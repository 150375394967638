import { h, Fragment } from "preact";
import { TopMenu } from "./topmenu";

export const Layout = ({children}) => (
  <Fragment>
      <TopMenu />
      <div className="page-container">
        {children}
      </div>
    </Fragment>
);