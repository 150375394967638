import { Fragment, h } from "preact";
import { BaseComponent } from "../components/baseComponent";
import { Code } from "../components/Code";
import { TopMenu } from "../components/topmenu";
import { PageMenu } from "../components/pageMenu";
import { TableOfContents } from "../components/tableOfContents";
import { Headline } from "../components/headline";
import { Layout } from "../components/layout";
import { Contents } from "../components/contents";

export class DotnetSdkPage extends BaseComponent<any, any> {
    render() {
        return (
            <Layout>
                <PageMenu />

                <TableOfContents>
                    contents go here
                </TableOfContents>

                <Contents>

                    <h2>.NET Framework</h2>
                    <p><b>to-do: example of how to use the nuget package in any .net project...</b></p>

                    <p>In Visual Studio install the package via the graphical Package Manager, or the Package Manager Console:</p>
                    <Code>PM&gt; Install-Package ConfigurationCMS.SDK</Code>
                    <p>Alternatively it can be installed on the command line via dotnet:</p>
                    <Code>dotnet add package ConfigurationCMS.SDK</Code>

                    <hr />

                    <h2>.NET Core / .NET5</h2>
                    <p><b>to-do: example of how to use the aspnetcore nuget package with a single line of code...</b></p>

                    <p>In Visual Studio install the package via the graphical Package Manager, or the Package Manager Console:</p>
                    <Code>PM&gt; Install-Package ConfigurationCMS.SDK.AspNetCore</Code>
                    <p>Alternatively it can be installed on the command line via dotnet:</p>
                    <Code>dotnet add package ConfigurationCMS.SDK</Code>

                    <p>todo: example of setting up the provider, and list of the options that can be configured</p>

                </Contents>
            </Layout>
        );
    }
}

