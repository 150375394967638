import { Fragment, h } from "preact";
import { BaseComponent } from "../components/baseComponent";
import { Layout } from "../components/layout";
import { Contents } from "../components/contents";
import { Markdown } from "../components/Markdown";

export class TestPage extends BaseComponent<any, any> {
    render() {
        return (
            <Layout>

                <Contents>
                    <Markdown>
{`

---
__Advertisement :)__

- __[pica](https://nodeca.github.io/pica/demo/)__ - high quality and fast image
  resize in browser.
- __[babelfish](https://github.com/nodeca/babelfish/)__ - developer friendly
  i18n with plurals support and easy syntax.

You will like those projects!

---

# h1 Heading 8-)
## h2 Heading
### h3 Heading
#### h4 Heading
##### h5 Heading
###### h6 Heading


## Emphasis

**This is bold text**

__This is bold text__

*This is italic text*

_This is italic text_

~~Strikethrough~~


## Blockquotes


> Blockquotes can also be nested...
>> ...by using additional greater-than signs right next to each other...
> > > ...or with spaces between arrows.




`}
</Markdown>

                </Contents>

            </Layout>
        );
    }
}

