import { Fragment, h } from "preact";
import { BaseComponent } from "../components/baseComponent";
import { Code } from "../components/Code";
import { PageMenu } from "../components/pageMenu";
import { TableOfContents } from "../components/tableOfContents";
import { Headline } from "../components/headline";
import { Layout } from "../components/layout";
import { Contents } from "../components/contents";

export class IntroPage extends BaseComponent<any, any> {
    render() {
        return (
            <Layout>
                <PageMenu />

                <TableOfContents>
                    contents go here
                </TableOfContents>

                <Contents>

                    <h2>Introduction</h2>

                    <p><b>to-do: introduction to the system, aimed at developers / technical people.</b></p>
                    <p>ConfigurationCMS is a system for managing a moderate amount of settings in a highly flexible manner.</p>

                    <p>ConfigurationCMS consists of a database and API for storing, setting and querying values, a UI to let human users manage the values, and a number of SDKs for developers to easily integrate configurations into their apps or services.</p>

                    <p><i>A sparse n-dimensional table</i></p>
                    <p>
                        Users create key-value pairs, grouped in <i>containers</i>, and can specify any number of <i>dimensions</i> in which the values can vary.
                        Key/values are set for a specific point on each dimension, and when the container is queried by specifying the point for each dimension, only the relevant values are returned.
                        What makes dimensions powerful is that specifying them is optional; if a key/value is set without specifying a point for a dimension, it applies to all points along this dimension.
                        This lets us treat the container as a sparse table, where values are inherited by default, and we only need to specify the values that must override the defaults..
                    </p>
                    <p>
                        For example, using ConfigurationCMS to store localizations, the user would create a container named "Localization", with a dimension named "Language" and dimensional points such as "English", "German", "Japanese" etc.
                        Key/values are then added for each translated string, for each point along the language dimension.
                        To support localizing a multi-tenant application, the user might extend the container with an extra dimension named "Tenant", with points such as "Customer A", "Customer B" etc.
                        Instead of specifying translated values for each combination of key and tenant, the user only has to add new values when a tenant requires a value that is different from the default-value for the language.
                        As additional dimensions are added to a container, the sparseness can drastically reduce the total number of values that must be set.
                    </p>
                    <p><i>Queries and dimensions</i></p>

                    <p>
                        <table className="demotable">
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Value</th>
                                    <th>Dimension</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Button-YES</td>
                                    <td>Yes</td>
                                    <td>language: English</td>
                                </tr>
                                <tr>
                                    <td>Button-OK</td>
                                    <td>Ja</td>
                                    <td>language: German</td>
                                </tr>
                                <tr>
                                    <td>Button-OK</td>
                                    <td>I accept</td>
                                    <td>language: English, tenant: CustomerB</td>
                                </tr>
                            </tbody>
                        </table>
                    </p>

                    <Headline>Auditing and bi-temporal queries</Headline>

                    <hr />

                    <p><b>to-do: describe the concepts</b></p>
                    <ul>
                        <li>Containers</li>
                        <li>Dimensions</li>
                        <li>Entries</li>
                        <li>Queries/projections</li>
                        <li>Changesets</li>
                        <li>Publication</li>
                        <li>Permissions</li>
                        <li></li>
                    </ul>

                </Contents>
            </Layout>
        );
    }
}

