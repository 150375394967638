// tslint:disable: one-variable-per-declaration

// --- copied from https://github.com/developit/dlv
export function delve(obj, key, def?, p?, undef?) {
    key = key.split ? key.split('.') : key;
    for (p = 0; p < key.length; p++) {
        obj = obj ? obj[key[p]] : undef;
    }
    return obj === undef ? def : obj;
}

// --- copied from https://github.com/developit/linkstate
export function linkState(component, key, eventPath?, additionalState?) {
    const path = key.split(".");
    const cache = component.__lsc || (component.__lsc = {});

    return cache[key + eventPath] || (cache[key + eventPath] = function (e) {
        let t = e && e.target || this,
            state = additionalState ? { ...additionalState } : {},
            obj = state,
            v = typeof eventPath === 'string' ? delve(e, eventPath) : t.nodeName ? (t.type.match(/^che|rad/) ? t.checked : t.value) : e,
            i = 0;
        for (; i < path.length - 1; i++) {
            obj = obj[path[i]] || (obj[path[i]] = !i && component.state[path[i]] || {});
        }
        obj[path[i]] = v;
        component.setState(state);
    });
}
