import { Fragment, h } from "preact";
import { BaseComponent } from "../components/baseComponent";
import { Code } from "../components/Code";
import { PageMenu } from "../components/pageMenu";
import { TableOfContents, TocItem } from "../components/tableOfContents";
import { Headline } from "../components/headline";
import { Layout } from "../components/layout";
import { Contents } from "../components/contents";

export class FrontPage extends BaseComponent<any, any> {
    render() {
        return (
            <Layout>
                <PageMenu />

                <TableOfContents>
                    <TocItem id="introduction" title="Introduction" />
                    <TocItem id="apis" title="APIs" />
                    <TocItem id="rest" title="REST" indent />
                    <TocItem id="graphql" title="GraphQL" indent />
                    <TocItem id="sdks" title="SDKs" />
                    <TocItem id="net" title=".NET" indent />
                    <TocItem id="unity" title="Unity" indent />
                    <TocItem id="javascript" title="Javascript" indent />
                </TableOfContents>

                <Contents>
                    <h1>Work in progress :)</h1>
                    <p>Welcome to the interim documentation for ConfigurationCMS.</p>

                    <section id="introduction">
                        <p><b>to-do: introduction to the system, aimed at developers / technical people.</b></p>
                        <p>ConfigurationCMS is a system for managing a moderate amount of settings in a highly flexible manner.</p>

                        <p>ConfigurationCMS consists of a database and API for storing, setting and querying values, a UI to let human users manage the values, and a number of SDKs for developers to easily integrate configurations into their apps or services.</p>

                    </section>
                    <hr />

                    <section id="example">
                        <h2>Example</h2>
                        <p>to-do: overview of example configuration for some product with a website and service and mobile app.</p>

                    </section>
                    <hr />

                    <section id="apis">

                        <h2>APIs</h2>

                        <p>All the functionality of ConfigurationCMS is exposed through two separate APIs that can be consumed from any language. There is a classical REST-based API, and a modern GraphQL API,  ...</p>
                        <p>The ConfigurationCMS UI is implemented entirely through these public APIs, so everything is available for automation.</p>

                        <section id="rest">
                            <h3>REST</h3>
                            <p><b>to-do: describing the REST endpoints and linking to Swagger</b></p>
                            <p>ConfigurationCMS has a REST API with ...</p>
                            <p>Why we are using REST</p>
                            <p>The root resource of the REST endpoint for your account is at https:///&lt;youranme&gt;.configurationcms.com/api and Swagger ...</p>
                        </section>

                        <section id="graphql">
                            <h3>GraphQL</h3>
                            <p><b>to-do: describing the GraphQL schema and linking to GraphQL-Playgrund</b></p>
                            <p>Why we are using GraphQL</p>
                            <p>ConfigurationCMS has a GraphQL API with ...</p>
                            <p>The GraphQL endpoint for your account is at https:///&lt;youranme&gt;.configurationcms.com/graphql and the query playground and Voyager UI's are at https:///&lt;youranme&gt;.configurationcms.com/graphql/playground and https:///&lt;youranme&gt;.configurationcms.com/graphql/voyager respectively.</p>
                        </section>

                    </section>

                    <hr />

                    <section id="sdks">

                        <h2>SDKs</h2>
                        <p>There are two types of packages available. Full packages exposing all the functionality of the API, intended for automation purposes, and lightweight packages that only consume configurations, intended for embedding into client services or end-user client applications.</p>

                        <section id="net">
                            <h3>.NET</h3>

                            <p>Todo: brief description of the different packages.  .netstandard package that exposes everything.  .netstandard package that exposes only whats needed to retrieve configurations.  .netcore/net5 package exposing only whats needed to retrieve configurations and inject them into the microsoft configuration system. </p>
                        </section>

                        <section id="unity">
                            <h3>Unity</h3>

                            <p>Todo: brief description of the packages available for Unity.  The .netstandard packages mentioned above, and a unity package with components that ...</p>
                        </section>

                        <section id="javascript">
                            <h3>Javascript</h3>
                            <p><b>Todo: brief description of the packages available</b></p>

                            <p>Full package that exposes all the functionality of the SDKs and is used by the UI ...</p>
                            <p>Lightweight package exposing only the functionality required to consume configurations, and optionally subscribe to changes ...</p>
                            <p>Lightweight package that adds a React hook and HOC for configuration changes ...</p>
                        </section>

                    </section>

                </Contents>

            </Layout>
        );
    }
}

