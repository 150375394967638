import { h, Fragment } from "preact";
import { Link } from "preact-router/match";
import { useEffect, useState } from "preact/hooks";

function useWindowIsScrolled() {
    const [isScrolled, setIsScrolled] = useState(() => window.pageYOffset > 0);
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.pageYOffset > 5);
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    });
    return isScrolled;
}

export function TopMenu() {
    const isScrolled = useWindowIsScrolled();
    return (
        <Fragment>
        <div class={"topmenu" + (isScrolled ? " topmenu--scrolled" : "")}>
            <div class="topmenu__main" >
                <div class="topmenu__main__items">
                    <Link class="topmenu__main__link" href="/">developer.configurationcms.com</Link>
                </div>
            </div>
        </div>
        <div class={"topmenu__sub" + (isScrolled ? " topmenu__sub--scrolled" : "")}>
            <div className="topmenu__sub__items">
                <Link class="topmenu__sub__link" href="/">Guides</Link>
                <Link class="topmenu__sub__link" href="/">Samples</Link>
                <Link class="topmenu__sub__link" href="/">Reference</Link>
            </div>
        </div>
        </Fragment>
    );
}

// <Link className="topmenu__link" activeClassName="topmenu__link--active" href="/features">Features</Link>
// <Link className="topmenu__link" activeClassName="topmenu__link--active" href="/pricing">Pricing</Link>
// <Link className="topmenu__link" activeClassName="topmenu__link--active" href="/signup">Sign up</Link>
