import { Fragment, h } from "preact";
import { BaseComponent } from "../components/baseComponent";
import { Code } from "../components/Code";
import { TopMenu } from "../components/topmenu";
import { PageMenu } from "../components/pageMenu";
import { TableOfContents } from "../components/tableOfContents";
import { Headline } from "../components/headline";
import { Layout } from "../components/layout";
import { Contents } from "../components/contents";

export class GraphqlApiPage extends BaseComponent<any, any> {
    render() {
        return (
            <Layout>
                <PageMenu />

                <TableOfContents>
                    contents go here
                </TableOfContents>

                <Contents>

                    <h2>GraphQL</h2>
                    <p><b>to-do: describing the GraphQL schema and linking to GraphQL-Playgrund</b></p>
                    <p>ConfigurationCMS has a GraphQL API with ...</p>
                    <p>
                        <a href="https://demo.configurationcms.com/graphql/playground" target="_blank">https://demo.configurationcms.com/graphql/playground</a>
                    </p>

                </Contents>
            </Layout>
        );
    }
}

