import { h } from "preact";
import { StateUpdater, useEffect, useState } from "preact/hooks";



function onNextFrame(callback) {
  setTimeout(function () {
      window.requestAnimationFrame(callback)
  }, 0)
}



let options = {
  root: null, // relative to document viewport 
  rootMargin: '0px', // margin around root. Values are similar to css property. Unitless values not allowed
  threshold: 0.2 // visible amount of item shown in relation to root
};




function useIntersectionObserver(id: string): boolean {
  const [isVisible, setIsVisible] = useState(false);

  function callback(changes) {
    changes.forEach(change => {
      //console.log(change);
      //console.log(change.target.id + " " + (change.isIntersecting ? " visible" : " hidden"));
      if(change.target.id === id) {
        setIsVisible(change.isIntersecting);
      }
    });
  }

  useEffect(() => {
    let observer = new IntersectionObserver(callback, options);

    // observer.observe()
    console.log("created observer");

    onNextFrame(() => {
      var element = document .getElementById(id);
      if(element) {
        observer?.observe(element);
      }
    });

    return () => {
        observer?.disconnect();
        observer = null;
    };
  });

  return isVisible;
}


export function TableOfContents(props) {
  return (
    <nav className="menu__toc">
      <div className="stick-under-menu">
        <div className="menu__toc__title">Table of contents</div>
        {props.children}
      </div>
    </nav>
  );
}


export function TocItem({title, id, indent = false}) {
  const isVisible = useIntersectionObserver(id);

  return (
    <a className={(isVisible ? "visible" : "") + (indent ? " indent" : "")} href={"#" + id}>{title}</a>
  );
}