import { Fragment, h } from "preact";
import { BaseComponent } from "../components/baseComponent";
import { Code } from "../components/Code";
import { TopMenu } from "../components/topmenu";
import { PageMenu } from "../components/pageMenu";
import { TableOfContents } from "../components/tableOfContents";
import { Headline } from "../components/headline";
import { Layout } from "../components/layout";
import { Contents } from "../components/contents";

export class JavascriptSdkPage extends BaseComponent<any, any> {
    render() {
        return (
            <Layout>
                <PageMenu />

                <TableOfContents>
                    contents go here
                </TableOfContents>

                <Contents>

                    <h2>Javascript SDKs</h2>
                    <p><b>to-do: example of how to use the npm package in any frontend or nodejs javascript/typescript project...</b></p>

                    <p>Full package that exposes all the functionality of the SDKs and is used by the UI itself.</p>
                    <p>Lightweight package exposing only the functionality required to consume configurations, and optionally subscribe to changes ...</p>

                    <hr />
                    <h3>Full package</h3>

                    <p>Quick overview of groups of functionality </p>

                    <p>Link to the reference section</p>

                    <hr />
                    <h3>Lightweight package</h3>

                    <p>Install the package via npm or your favorite alternative:</p>
                    <Code>npm install --save configurationcms</Code>

                    <p>todo: example of a simple fetch() that retrieves configurations with an API key</p>

                    <p>todo: example of using the lightweight package to retrieve configurations with an API key</p>

                    <p>todo: example of subscribing to changes using the lightweight package<br />
                     todo: extending the example to wrap the callback with changes in an IObservable</p>

                     <p>todo: list of the options that can be given to the package when fetching configurations</p>

                     <p>todo: example of configuring the package to cache data in localstorage, and how the user can provide their own callbacks for caching.<br />
                        todo: setting up an npm script to build a file as a local cache that can be included in the distribution.<br />
                        todo: setting up a webpack task to build and include a file to act as local cache.</p>

                    <hr />
                    <h3>React</h3>
                    <p>Lightweight package that builds on top of the lightweight base JS packag, and adds a React hook and HOC for configuration changes ...</p>
                    <p><b>to-do: example of how to use the npm react package in a frontend react project...</b></p>

                    <p>Install the package via npm or your favorite alternative:</p>
                    <Code>npm install --save configurationcms-react</Code>

                    <p>todo: example of consuming configuration via the hook</p>
                    <p>todo: example of consuming configuration via a HOC</p>

                </Contents>
            </Layout>
        );
    }
}

