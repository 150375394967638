import { Fragment, h } from "preact";
import { BaseComponent } from "../components/baseComponent";
import { Code } from "../components/Code";
import { PageMenu } from "../components/pageMenu";
import { TableOfContents } from "../components/tableOfContents";
import { Headline } from "../components/headline";
import { Layout } from "../components/layout";
import { Contents } from "../components/contents";

export class ExamplePage extends BaseComponent<any, any> {
    render() {
        return (
            <Layout>
                <PageMenu />

                <TableOfContents>
                    contents go here
                </TableOfContents>

                <Contents>

                    <h2>Example</h2>

                    <p>Example that goes through setting up a new tenant, and some basic configurations that would be applicable for a system comprised of a backend, a web client, and a mobile app.</p>

                    <p>Backend configuration</p>

                    <p>Feature toggles</p>

                    <p>Localization, shared betweeen the web app and mobile app</p>

                </Contents>
            </Layout>
        );
    }
}

