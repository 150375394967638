import { h } from "preact";
import { Link } from "preact-router/match";

export function PageMenu(props) {
  return (
    <div className="menu__page">
        <div class="menu__page__links stick-under-menu">
          <Link activeClassName="current" href="/">Frontpage</Link>
          <Link activeClassName="current" href="/introduction">Introduction</Link>
          <Link activeClassName="current" href="/example">Example</Link>
          <Link activeClassName="current" href="/rest">REST API</Link>
          <Link activeClassName="current" href="/graphql">GraphQL API</Link>
          <Link activeClassName="current" href="/dotnet">.NET SDKs</Link>
          <Link activeClassName="current" href="/javascript">Javascript SDKs</Link>
        </div>
    </div>
  );
}
