import Router from 'preact-router';
import { h, render } from "preact";
import { FrontPage } from "./pages/frontpage";
import { DotnetSdkPage } from './pages/dotnetsdk';
import { GraphqlApiPage } from './pages/graphqlapi';
import { RestApiPage } from './pages/restapi';
import { IntroPage } from './pages/intro';
import { TestPage } from './pages/test';
import { JavascriptSdkPage } from './pages/javascriptsdk';
import { ExamplePage } from './pages/example';

const routes = (
    <Router>
        <FrontPage path="/" />
        <IntroPage path="/introduction" />
        <ExamplePage path="/example" />
        <DotnetSdkPage path="/dotnet" />
        <JavascriptSdkPage path="/javascript" />
        <GraphqlApiPage path="/graphql" />
        <RestApiPage path="/rest" />
        <TestPage path="/test/page" />

    </Router>
);

render(routes, document.getElementById("app")
);
