import { Component, h } from "preact";
import { linkState } from "../utils";

export abstract class BaseComponent<P, S> extends Component<P, S> {

    // todo: promise chain helper to abort if the component was unmounted

    private _isUnmounted = false;

    get isUnmounted() {
        return this._isUnmounted;
    }

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        this._isUnmounted = true;
    }

    linkState = (name: keyof (S), additionalState?: { [key: string]: any }) => linkState(this, name, undefined, additionalState);
}
