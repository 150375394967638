import { ComponentChildren, Fragment, h } from "preact";
import * as MarkdownIt from "markdown-it";

interface Props {
    children?: ComponentChildren;
}

export function Markdown(props: Props) {

    console.log(props.children);

    var md = new MarkdownIt();

    if(typeof props.children === "string")
        return <div className="rootmarkdown" dangerouslySetInnerHTML={{__html: md.render(props.children)}} />

    return (
        <Fragment>{
            (props.children ? [ ... (props.children as [])] : []).map(item =>
                typeof item === "string"
                    ? <div className="markdown" dangerouslySetInnerHTML={{__html: md.render(item)}} />
                    : item )
            }
        </Fragment>
    );
}
